import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, SaveOmnyCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  omnyApiKey: yup.string().required("'Api key' é obrigatória"),
});

const OmnyIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const { data: omnyConfig, refetch: refetchOmnyConfig } = useQuery(apiRoutes.getOmnyConfig(), async () => {
  //   const { data } = await IntegrationsService.getOmnyConfig();
  //   return data;
  // }, {
  //   onSuccess: (data) => {
  //     setValue("isOmnyActive", data.isOmnyActive || '');
  //   },
  // });

  const saveOmnyCredentials = useMutation(
    (data: SaveOmnyCredentialsDto) => IntegrationsService.saveOmnyCredentials(data),
    {
      onSuccess: () => {
        // refetchOmnyConfig();
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await saveOmnyCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Api Key</FormLabel>
            <Input
            placeholder="******************"
            type="password"
              {...register("omnyApiKey")}
              isInvalid={errors.omnyApiKey?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.omnyApiKey?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveOmnyCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default OmnyIntegrationPage;
