import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, SaveCartPandaCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  cartPandaAccessToken: yup.string().required("'Access Token' é obrigatório"),
  cartPandaShopSlug: yup.string().required("'Shop Slug' é obrigatória"),
});

const CartPandaIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: cartPandaConfig, refetch: refetchCartPandaConfig } = useQuery(apiRoutes.getCartPandaConfig(), async () => {
    const { data } = await IntegrationsService.getCartPandaConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("cartPandaShopSlug", data.cartPandaShopSlug || '');
    },
  });

  const saveCartPandaCredentials = useMutation(
    (data: SaveCartPandaCredentialsDto) => IntegrationsService.saveCartPandaCredentials(data),
    {
      onSuccess: () => {
        refetchCartPandaConfig();
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await saveCartPandaCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Access Token</FormLabel>
            <Input
              placeholder="Cole o Access Token aqui"
              {...register("cartPandaAccessToken")}
              isInvalid={errors.cartPandaAccessToken?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.cartPandaAccessToken?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Shop Slug</FormLabel>
            <Input
placeholder="Cole o Shop Slug aqui (ex: loja-teste)"
              {...register("cartPandaShopSlug")}
              isInvalid={errors.cartPandaShopSlug?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.cartPandaShopSlug?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveCartPandaCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default CartPandaIntegrationPage;
