import { useMediaQuery } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { appPaths } from '../constants/app-paths';
import {
  MAX_MOBILE_SCREEN_WIDTH,
  screenSizes,
} from '../constants/screen-sizes';
import { CrudAutoReplyModalProvider } from '../hooks/useCrudAutoReplyModal';
import { CrudCompanyDefinedFieldModalProvider } from '../hooks/useCrudCompanyDefinedFieldModal';
import { CrudQuickReplyModalProvider } from '../hooks/useCrudQuickReplyModal';
import { CrudTagModalProvider } from '../hooks/useCrudTagModal';
import { SendTemplateModalProvider } from '../hooks/useSendTemplateModal';
import AppLayout from '../layouts/AppLayout';
import AutomationsLayout from '../layouts/AutomationsLayout';
import IntegrationSettingsLayout from '../layouts/IntegrationSettingsLayout';
import SettingsLayout from '../layouts/SettingsLayout';
import AutoRepliesPage from '../pages/AutomationsPage/AutoRepliesPage';
import AutomaticSortingPage from '../pages/AutomationsPage/AutoSortingPage';
import BackgroundAutomationsPage from '../pages/AutomationsPage/BackgroundAutomationsPage';
import BackgroundAutomationDetailsPage from '../pages/AutomationsPage/BackgroundAutomationsPage/BackgroundAutomationDetailsPage';
import CreateBackgroundAutomationPage from '../pages/AutomationsPage/BackgroundAutomationsPage/CreateBackgroundAutomationPage';
import EditBackgroundAutomationPage from '../pages/AutomationsPage/BackgroundAutomationsPage/EditBackgroundAutomationPage';
import MessagesFlowPage from '../pages/AutomationsPage/MessageFlowsPage';
import EditMessageFlowPage from '../pages/AutomationsPage/MessageFlowsPage/EditMessageFlowPage';
import CreateCampaignPage from '../pages/CampaignsPage/CreateCampaignPage';
import SmsCampaignsPage from '../pages/CampaignsPage/SmsCampaignsPage';
import WhatsappCampaignsPage from '../pages/CampaignsPage/WhatsappCampaignsPage';
import WhatsappCampaignDetailsPage from '../pages/CampaignsPage/WhatsappCampaignsPage/WhatsappCampaignDetailsPage';
import CustomersPage from '../pages/CustomersPage';
import HomePage from '../pages/HomePage';
import InboxPage from '../pages/InboxPage';
import MessageTemplatesPage from '../pages/MessageTemplatePage';
import CreateMessageTemplatePage from '../pages/MessageTemplatePage/CreateMessageTemplatePage';
import ReportsOverviewPage from '../pages/ReportsPage/OverviewPage';
import ReportsLayout from '../pages/ReportsPage/ReportsLayout';
import AccountSettingsPage from '../pages/SettingsPage/AccountSettingsPage';
import ColumnSettingsPage from '../pages/SettingsPage/ColumnSettingsPage';
import SettingsPage from '../pages/SettingsPage/GeneralSettingsPage';
import IntegrationSettingsPage from '../pages/SettingsPage/IntegrationSettingsPage';
import BlingIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/BlingIntegrationPage';
import LojaIntegradaIntegrationsPage from '../pages/SettingsPage/IntegrationSettingsPage/LojaIntegradaIntegrationsPage';
import ShopifyIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/ShopifyIntegrationPage';
import VtexIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/VtexIntegrationPage';
import CartPandaIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/CartPandaIntegrationPage';
import InvoicesPage from '../pages/SettingsPage/InvoicesPage';
import QuickRepliesPage from '../pages/SettingsPage/QuickRepliesPage';
import TagSettingsPage from '../pages/SettingsPage/TagSettingsPage';
import BusinessHoursSettingsPage from '../pages/SettingsPage/BussinessHoursSettingsPage/BusinessHoursSettingsPage';
import { MixpanelService } from '../services/mixpanel.service';
import OmnyIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/OmnyIntegrationPage';
import TrayIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/TrayIntegrationPage';
import OmieIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/OmieIntegrationPage';
import MagentoIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/MagentoIntegrationPage';
import MagazordIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/MagazordIntegrationPage';
import VisualECommerceIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/VisualECommerceIntegrationPage';
import UnboxIntegrationPage from '../pages/SettingsPage/IntegrationSettingsPage/UnboxIntegrationPage';

const ConversationsElement = () => (
  <SendTemplateModalProvider>
    <InboxPage />
  </SendTemplateModalProvider>
);

const routes = [
  {
    path: appPaths.conversations(),
    element: <ConversationsElement />,
  },
  {
    path: appPaths.home(),
    element: <HomePage />,
  },
  {
    path: appPaths.automations.messageFlows.editMessageFlow(':flowId'),
    element: (
      <ReactFlowProvider>
        <EditMessageFlowPage />
      </ReactFlowProvider>
    ),
  },
  {
    path: appPaths.automations.messageFlows.index(),
    element: (
      <AutomationsLayout>
        <MessagesFlowPage />
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.autoSorting(),
    element: (
      <AutomationsLayout>
        <AutomaticSortingPage />
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.autoReplies(),
    element: (
      <AutomationsLayout>
        <CrudAutoReplyModalProvider>
          <AutoRepliesPage />
        </CrudAutoReplyModalProvider>
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.backgroundAutomations.index(),
    element: (
      <AutomationsLayout>
        <CrudAutoReplyModalProvider>
          <BackgroundAutomationsPage />
        </CrudAutoReplyModalProvider>
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.backgroundAutomations.createAutomation(),
    element: (
      <AutomationsLayout>
        <CrudAutoReplyModalProvider>
          <CreateBackgroundAutomationPage />
        </CrudAutoReplyModalProvider>
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.backgroundAutomations.editAutomation(
      ':automationId',
    ),
    element: (
      <AutomationsLayout>
        <CrudAutoReplyModalProvider>
          <EditBackgroundAutomationPage />
        </CrudAutoReplyModalProvider>
      </AutomationsLayout>
    ),
  },
  {
    path: appPaths.automations.backgroundAutomations.details(':automationId'),
    element: <BackgroundAutomationDetailsPage />,
  },
  {
    path: appPaths.settings.general(),
    element: (
      <SettingsLayout>
        <SettingsPage />
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.customColumns(),
    element: (
      <SettingsLayout>
        <CrudCompanyDefinedFieldModalProvider>
          <ColumnSettingsPage />
        </CrudCompanyDefinedFieldModalProvider>
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.tags(),
    element: (
      <SettingsLayout>
        <CrudTagModalProvider>
          <TagSettingsPage />
        </CrudTagModalProvider>
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.accountSettings(),
    element: (
      <SettingsLayout>
        <AccountSettingsPage />
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.shopify(),
    element: (
      <IntegrationSettingsLayout title="Shopify">
        <ShopifyIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.lojaIntegrada(),
    element: (
      <IntegrationSettingsLayout title="Loja Integrada">
        <LojaIntegradaIntegrationsPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.bling(),
    element: (
      <IntegrationSettingsLayout title="Bling">
        <BlingIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.vtex(),
    element: (
      <IntegrationSettingsLayout title="VTEX">
        <VtexIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.cartPanda(),
    element: (
      <IntegrationSettingsLayout title="Cart Panda">
        <CartPandaIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },{
    path: appPaths.settings.integrationSettings.unbox(),
    element: (
      <IntegrationSettingsLayout title="Unbox">
        <UnboxIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.visualECommerce(),
    element: (
      <IntegrationSettingsLayout title="Visual E-Commerce">
        <VisualECommerceIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.magazord(),
    element: (
      <IntegrationSettingsLayout title="Magazord">
        <MagazordIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.magento(),
    element: (
      <IntegrationSettingsLayout title="Magento">
        <MagentoIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.omny(),
    element: (
      <IntegrationSettingsLayout title="Omny">
        <OmnyIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.omie(),
    element: (
      <IntegrationSettingsLayout title="Omie">
        <OmieIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.tray(),
    element: (
      <IntegrationSettingsLayout title="Tray">
        <TrayIntegrationPage />
      </IntegrationSettingsLayout>
    ),
  },
  {
    path: appPaths.settings.integrationSettings.index(),
    element: (
      <SettingsLayout>
        <IntegrationSettingsPage />
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.quickReplies(),
    element: (
      <SettingsLayout>
        <CrudQuickReplyModalProvider>
          <QuickRepliesPage />
        </CrudQuickReplyModalProvider>
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.businessHours(),
    element: (
      <SettingsLayout>
        <BusinessHoursSettingsPage />
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.settings.invoices(),
    element: (
      <SettingsLayout>
        <InvoicesPage />
      </SettingsLayout>
    ),
  },
  {
    path: appPaths.customers.index(),
    element: (
      <SendTemplateModalProvider>
        <CustomersPage />
      </SendTemplateModalProvider>
    ),
  },
  {
    path: appPaths.messageTemplates.index(),
    element: <MessageTemplatesPage />,
  },
  {
    path: appPaths.messageTemplates.createMessageTemplate(),
    element: <CreateMessageTemplatePage />,
  },
  {
    path: appPaths.campaigns.create(),
    element: <CreateCampaignPage />,
  },
  {
    path: appPaths.campaigns.sms.index(),
    element: <SmsCampaignsPage />,
  },
  {
    path: appPaths.campaigns.whatsapp.index(),
    element: <WhatsappCampaignsPage />,
  },
  {
    path: appPaths.campaigns.whatsapp.details(':campaignId'),
    element: <WhatsappCampaignDetailsPage />,
  },
  {
    path: appPaths.reports.index(),
    element: (
      <ReportsLayout>
        <ReportsOverviewPage />
      </ReportsLayout>
    ),
  },
];

const AppRoutes = () => {
  const location = useLocation();
  const [isMobile] = useMediaQuery(screenSizes.mobile);

  useEffect(() => {
    MixpanelService.track('page-view', { path: location.pathname });
  }, [location.pathname]);

  if (window.screen.width < MAX_MOBILE_SCREEN_WIDTH) {
    return <ConversationsElement />;
  }

  return (
    <Routes>
      {routes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={<AppLayout>{element}</AppLayout>}
        />
      ))}
      <Route
        path={'/*'}
        element={
          <Navigate
            to={isMobile ? appPaths.conversations() : appPaths.home()}
            replace
          />
        }
      />
    </Routes>
  );
};

export default AppRoutes;
