import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, SaveVisualECommerceCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  visualECommerceApiKey: yup.string().required("'Api Key' é obrigatório"),
  visualECommerceStoreDomain: yup.string()
      .required("'Store Domain' é obrigatório")
      .matches(
        /^(?!https?:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}?$/,
        "'Store Domain' deve ser um domínio válido e não deve incluir http ou https"
      ),
});

const VisualECommerceIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: visualECommerceConfig, refetch: refetchVisualECommerceConfig } = useQuery(apiRoutes.getVisualECommerceConfig(), async () => {
    const { data } = await IntegrationsService.getVisualECommerceConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("visualECommerceStoreDomain", data.visualECommerceStoreDomain || '');
    },
  });

  const saveVisualECommerceCredentials = useMutation(
    (data: SaveVisualECommerceCredentialsDto) => IntegrationsService.saveVisualECommerceCredentials(data),
    {
      onSuccess: () => {
        refetchVisualECommerceConfig();
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await saveVisualECommerceCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Store Domain</FormLabel>
            <Input
          placeholder="Ex.: loja.exemplo.com"
              {...register("visualECommerceStoreDomain")}
              isInvalid={errors.visualECommerceStoreDomain?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.visualECommerceStoreDomain?.message}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>Api Key</FormLabel>
            <Input
              placeholder="******************"
              type="password"
              {...register("visualECommerceApiKey")}
              isInvalid={errors.visualECommerceApiKey?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.visualECommerceApiKey?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveVisualECommerceCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default VisualECommerceIntegrationPage;
