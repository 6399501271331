import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { BlingService } from "../../../../services/bling.service";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, SaveBlingCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  blingClientId: yup.string().required("'Client ID' é obrigatório"),
  blingClientSecret: yup.string().required("'Client Secret' é obrigatória"),
});

const BlingIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: blingConfig, refetch: refetchBlingConfig } = useQuery(apiRoutes.getBlingConfig(), async () => {
    const { data } = await IntegrationsService.getBlingConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("blingClientId", data.blingClientId || '');
    },
  });

  const saveBlingCredentials = useMutation(
    (data: SaveBlingCredentialsDto) => IntegrationsService.saveBlingCredentials(data),
    {
      onSuccess: () => {
        refetchBlingConfig();
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const { data: blingAuthUrl } = useQuery(
    apiRoutes.getBlingAuthUrl(),
    async () => {
      const { data } = await BlingService.getBlingAuthUrl();
      return data.url;
    }
  );

  async function onSubmit(data: any) {
    await saveBlingCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Client ID</FormLabel>
            <Input
              placeholder="Cole o Client ID aqui"
              {...register("blingClientId")}
              isInvalid={errors.blingClientId?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.blingClientId?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Client Secret</FormLabel>
            <Input
              placeholder="**********************"
              type="password"
              sx={{
                '&::placeholder': {
                  color: 'black'
                }
              }}
              {...register("blingClientSecret")}
              isInvalid={errors.blingClientSecret?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.blingClientSecret?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveBlingCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
      <Divider my={4} />
      <Button as="a" href={blingAuthUrl} width="fit-content" isDisabled={!blingConfig?.blingClientId}>
        Conectar com bling
      </Button>
    </Box>
  );
};

export default BlingIntegrationPage;
