import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { NodeProps } from "reactflow";
import { apiRoutes } from "../../../../../../constants/api-routes";
import { SendWhatsappMessageTemplateNodeData } from "../../../../../../types/ReactFlowNode";
import NodeLayout from "../NodeLayout";
import { MessageTemplatesService } from "../../../../../../services/message-templates.service";
import { colors } from "../../../../../../constants/colors";
import { CgTemplate } from "react-icons/cg";

const SendWhatsappMessageTemplateNode = (props: NodeProps<SendWhatsappMessageTemplateNodeData>) => {
  const { t } = useTranslation();

  const { data: messageTemplates } = useQuery(
    apiRoutes.listMessageTemplates(),
    async () => {
      const { data } = await MessageTemplatesService.listMessageTemplates();
      return data;
    },
    {
      staleTime: 1000 * 15,
    }
  );
  const messageTemplate = messageTemplates?.find((tag) => tag.id === props.data.messageTemplateId);

  return (
    <NodeLayout
      id={props.id}
      title={t(`enums.FlowNodeType.${props.type}`)}
      icon={<CgTemplate />}
      color={colors.tulip}
    >
      <Box
        mt="20px"
        width="100%"
        borderRadius={"10px"}
        bgColor={"#F3F3F3"}
        padding="15px"
        height="fit-content"
      >
        {messageTemplate?.name || ""}
      </Box>
    </NodeLayout>
  );
};

export default SendWhatsappMessageTemplateNode;
