import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Select, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, SaveMagentoCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  magentoApiKey: yup.string().required("'Api Key' é obrigatório"),
  magentoUser: yup.string().required("'Api User' é obrigatório"),
  magentoApiUrl: yup.string().url().required("'Api Url' é obrigatória"),
  magentoVersion: yup.string().required("'Versão' é obrigatório"),
});

const MagentoIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: magentoConfig, refetch: refetchMagentoConfig } = useQuery(apiRoutes.getMagentoConfig(), async () => {
    const { data } = await IntegrationsService.getMagentoConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("magentoUser", data.magentoUser || '');
      setValue("magentoVersion", data.magentoVersion || '');
      setValue("magentoApiUrl", data.magentoApiUrl || '');
    },
  });

  const saveMagentoCredentials = useMutation(
    (data: SaveMagentoCredentialsDto) => IntegrationsService.saveMagentoCredentials(data),
    {
      onSuccess: () => {
        refetchMagentoConfig();
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await saveMagentoCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>

<FormControl>
  <FormLabel>Api Url</FormLabel>
  <Input
placeholder="Ex.: https://api-url-teste.com.br"
    {...register("magentoApiUrl")}
    isInvalid={errors.magentoApiUrl?.message}
  />
  <Text color={colors.danger} fontSize="xs">
    {errors.magentoApiUrl?.message}
  </Text>
</FormControl>
          <FormControl>
            <FormLabel>Api User</FormLabel>
            <Input
              placeholder="Cole aqui o usuário da API"
              {...register("magentoUser")}
              isInvalid={errors.magentoUser?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.magentoUser?.message}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>Api Key</FormLabel>
            <Input
              placeholder="******************"
              type="password"
              {...register("magentoApiKey")}
              isInvalid={errors.magentoApiKey?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.magentoApiKey?.message}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>Versão</FormLabel>
            <Select
              placeholder="Selecione a versão"
              {...register("magentoVersion")}
              isInvalid={errors.magentoVersion?.message}
            >
              <option value="1.x">1.x</option>
              <option value="2.x">2.x</option>
            </Select>
            <Text color={colors.danger} fontSize="xs">
              {errors.magentoVersion?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveMagentoCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default MagentoIntegrationPage;
