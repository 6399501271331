import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, SaveUnboxCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  unboxMetabaseId: yup.string().required("'Metabase Id' é obrigatório"),
});

const UnboxIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: unboxConfig, refetch: refetchUnboxConfig } = useQuery(apiRoutes.getUnboxConfig(), async () => {
    const { data } = await IntegrationsService.getUnboxConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("unboxMetabaseId", data.unboxMetabaseId || '');
    },
  });

  const saveUnboxCredentials = useMutation(
    (data: SaveUnboxCredentialsDto) => IntegrationsService.saveUnboxCredentials(data),
    {
      onSuccess: () => {
        refetchUnboxConfig();
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await saveUnboxCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Metabase Id</FormLabel>
            <Input
              placeholder="Cole o Metabase id aqui"
              {...register("unboxMetabaseId")}
              isInvalid={errors.unboxMetabaseId?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.unboxMetabaseId?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveUnboxCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default UnboxIntegrationPage;
