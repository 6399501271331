import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, SaveMagazordCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  magazordApiDomain: yup.string()
      .required("'Api Domain' é obrigatório")
      .matches(
        /^(?!https?:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}?$/,
        "'Api Domain' deve ser um domínio válido e não deve incluir http ou https"
      ),
  magazordClientToken: yup.string().required("'Client Token' é obrigatória"),
  magazordClientPassword: yup.string().required("'Client Password' é obrigatória"),
});

const MagazordIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: magazordConfig, refetch: refetchMagazordConfig } = useQuery(apiRoutes.getMagazordConfig(), async () => {
    const { data } = await IntegrationsService.getMagazordConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("magazordApiDomain", data.magazordApiDomain || '');
    },
  });

  const saveMagazordCredentials = useMutation(
    (data: SaveMagazordCredentialsDto) => IntegrationsService.saveMagazordCredentials(data),
    {
      onSuccess: () => {
        refetchMagazordConfig();
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await saveMagazordCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Api Domain</FormLabel>
            <Input
              placeholder="Cole o Api Domain aqui"
              {...register("magazordApiDomain")}
              isInvalid={errors.magazordApiDomain?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.magazordApiDomain?.message}
            </Text>
          </FormControl>
          <FormControl>
            <FormLabel>Client Token</FormLabel>
            <Input
              placeholder="******************"
              type="password"
              {...register("magazordClientToken")}
              isInvalid={errors.magazordClientToken?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.magazordClientToken?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Client Password</FormLabel>
            <Input
              placeholder="******************"
              type="password"
              {...register("magazordClientPassword")}
              isInvalid={errors.magazordClientPassword?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.magazordClientPassword?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveMagazordCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default MagazordIntegrationPage;
