import {
  Container,
  DrawerBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import * as yup from "yup";
import { SendWhatsappMessageTemplateNodeData } from "../../../../../../../types/ReactFlowNode";
import { apiRoutes } from "../../../../../../../constants/api-routes";
import { colors } from "../../../../../../../constants/colors";
import InputSelect from "../../../../../../../components/InputSelect";
import { useState } from "react";
import { ListMessageTemplateItem, MessageTemplatesService } from "../../../../../../../services/message-templates.service";
import { MessageTemplate } from "../../../../../../../types/MessageTemplate";
import { MessageTemplateUtils } from "../../../../../../../utils/message-templates.utils";
import WhatsappTemplatePreview from "../../../../../../../components/WhatsappTemplatePreview";

const schema = yup
  .object({
    messageTemplate: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
    }),
  })
  .required();

export interface SendWhatsappMessageTemplateNodeEditorProps {
  data: SendWhatsappMessageTemplateNodeData;
  formId: string;
  onSaveNode: (data: any) => void;
}
const SendWhatsappMessageTemplateNodeEditor = ({
  data,
  formId,
  onSaveNode,
}: SendWhatsappMessageTemplateNodeEditorProps) => {
  const [messageTemplates, setMessageTemplates] = useState<ListMessageTemplateItem[]>([]);


  const [templateArgs, setTemplateArgs] = useState<{ [key: string]: string }>(
    data.templateArgs || {}
  );

  function handleChangeTemplateParameter(parameter: string, value: string) {
    setTemplateArgs({
      ...templateArgs,
      [parameter]: value,
    });
  }

  const { messageTemplateId } = data;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useQuery(apiRoutes.listMessageTemplates(), async () => {
    const { data } = await MessageTemplatesService.listMessageTemplates();
    return data;
  },
  {
    onSuccess: (data) => {
      const currentMessageTemplate: ListMessageTemplateItem | undefined = data.find((messageTemplate: MessageTemplate) => messageTemplate.id === messageTemplateId);
      if (currentMessageTemplate) {
        setValue("messageTemplate", {
          value: currentMessageTemplate.id,
          label: currentMessageTemplate.name,
        });
      }

      setMessageTemplates(data);
    }
  });

  const selectedMessageTemplate = messageTemplates.find((messageTemplate) => messageTemplate.id === watch("messageTemplate")?.value);
  const customParameters = MessageTemplateUtils.getCustomParametersInText(selectedMessageTemplate?.templateText || "");

  const handleFormSubmit = (data: any) => {
    const filteredTemplateArgs = Object.keys(templateArgs).reduce((acc, key) => {
      if (customParameters.includes(key)) {
        acc[key] = templateArgs[key];
      }
      return acc;
    }, {} as { [key: string]: string });


    const messageTemplateId = data.messageTemplate.value;
    const messageTemplateData = {
      messageTemplateId,
      templateArgs: filteredTemplateArgs,
    }
    onSaveNode({ ...data, ...messageTemplateData });
  }

  return (
    <DrawerBody>
      <form onSubmit={handleSubmit(handleFormSubmit)} id={formId}>
        <Flex flexDir={"column"} gap={3}>
          <FormControl>
            <FormLabel>Template</FormLabel>
            <Controller
              name="messageTemplate"
              control={control}
              render={({ field }) => (
                <InputSelect
                  placeholder="Selecionar template de mensagem"
                  options={messageTemplates.map((messageTemplate) => ({
                    value: messageTemplate.id,
                    label: messageTemplate.name,
                  }))}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.messageTemplate?.message}
            </Text>
          </FormControl>
          {selectedMessageTemplate && (
            customParameters.map((param) => (
              <FormControl isRequired key={param}>
                <FormLabel fontSize="sm">
                  {param.replaceAll("[", "").replaceAll("]", "")}
                </FormLabel>
                <Input
                  size="sm"
                  onChange={(e) =>
                    handleChangeTemplateParameter(param, e.target.value)
                  }
                  value={templateArgs[param] || ""}
                  required
                />
              </FormControl>
            ))
          )}

          {selectedMessageTemplate && (
            <WhatsappTemplatePreview
              message={selectedMessageTemplate.templateText}
              footer={selectedMessageTemplate.footerText}
              buttons={selectedMessageTemplate.messageTemplateButtons}
              fileUrl={selectedMessageTemplate.mediaUrl}
              height={"40vh"}
            />
          )}
        </Flex>
      </form>
    </DrawerBody>
  );
};

export default SendWhatsappMessageTemplateNodeEditor;
