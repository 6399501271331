import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Stack, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { apiRoutes } from "../../../../constants/api-routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors } from "../../../../constants/colors";
import { IntegrationsService, SaveTrayCredentialsDto } from "../../../../services/integrations.service";

const schema = yup.object({
  trayApiDomain: yup.string()
    .required("'Api Domain' é obrigatório")
    .matches(
      /^(?!https?:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}?$/,
      "'Api Domain' deve ser um domínio válido e não deve incluir http ou https"
    ),
  trayAuthCode: yup.string().required("'Auth Code' é obrigatória"),
});

const TrayIntegrationPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: trayConfig, refetch: refetchTrayConfig } = useQuery(apiRoutes.getTrayConfig(), async () => {
    const { data } = await IntegrationsService.getTrayConfig();
    return data;
  }, {
    onSuccess: (data) => {
      setValue("trayApiDomain", data.trayApiDomain || '');
    },
  });

  const saveTrayCredentials = useMutation(
    (data: SaveTrayCredentialsDto) => IntegrationsService.saveTrayCredentials(data),
    {
      onSuccess: () => {
        refetchTrayConfig();
        toast({
          title: "Configurações salvas com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  async function onSubmit(data: any) {
    await saveTrayCredentials.mutateAsync(data);
  }

  return (
    <Box>
      <Heading size="md">Configurar integração</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mt={4}>
          <FormControl>
            <FormLabel>Api Domain</FormLabel>
            <Input
              placeholder="Ex.: loja-tray.com.br"
              {...register("trayApiDomain")}
              isInvalid={errors.trayApiDomain?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.trayApiDomain?.message}
            </Text>
          </FormControl>

          <FormControl>
            <FormLabel>Auth Code</FormLabel>
            <Input
            placeholder="******************"
            type="password"
              {...register("trayAuthCode")}
              isInvalid={errors.trayAuthCode?.message}
            />
            <Text color={colors.danger} fontSize="xs">
              {errors.trayAuthCode?.message}
            </Text>
          </FormControl>

          <Flex justify="flex-end">
            <Button
              type="submit"
              color={colors.white}
              bgColor={colors.primary}
              width="fit-content"
              isLoading={saveTrayCredentials.isLoading}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};

export default TrayIntegrationPage;
