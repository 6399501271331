import { UrlUtils } from '../utils/url.utils';
import { EngagementActionsEnum } from '../types/EngagementActionsEnum';
import { CustomerFiltersEnum } from '../types/CustomerFiltersEnum';

interface CustomersPageQueryParams
  extends Partial<Record<CustomerFiltersEnum, string | number>> {
  isSendTemplateModalOpen?: boolean;
  selectedEngagementActionTypes?: EngagementActionsEnum;
}

export function getCustomersPageQueryParams(
  queryParams: CustomersPageQueryParams,
): string {
  return UrlUtils.convertObjectToQueryString(queryParams);
}

export const appPaths = {
  home: () => '/home',
  conversations: () => '/conversas',
  automations: {
    autoSorting: () => '/automacoes/triagem-inicial',
    autoReplies: () => '/automacoes/respostas-automaticas',
    messageFlows: {
      index: () => '/automacoes/fluxos-de-mensagem',
      editMessageFlow: (flowId: string) =>
        `/automacoes/fluxos-de-mensagem/${flowId}/editar`,
    },
    backgroundAutomations: {
      index: () => '/automacoes/envios-automatizados',
      // editAutomation: (automationId: string) => `/automacoes/envios-automatizados/${automationId}/editar`,
      createAutomation: () => '/automacoes/envios-automatizados/novo',
      editAutomation: (automationId: string) =>
        `/automacoes/envios-automatizados/${automationId}/editar`,
      details: (automationId: string) =>
        `/automacoes/envios-automatizados/${automationId}`,
    },
  },
  settings: {
    general: () => '/configuracoes/geral',
    customColumns: () => '/configuracoes/colunas-customizadas',
    accountSettings: () => '/configuracoes/conta',
    integrationSettings: {
      index: () => '/configuracoes/integracoes',
      bling: () => '/configuracoes/integracoes/bling',
      vtex: () => '/configuracoes/integracoes/vtex',
      shopify: () => '/configuracoes/integracoes/shopify',
      lojaIntegrada: () => '/configuracoes/integracoes/loja-integrada',
      wooCommerce: () => '/configuracoes/integracoes/woo-commerce',
      cartPanda: () => '/configuracoes/integracoes/cart-panda',
      omny: () => '/configuracoes/integracoes/omny',
      omie: () => '/configuracoes/integracoes/omie',
      tray: () => '/configuracoes/integracoes/tray',
      unbox: () => '/configuracoes/integracoes/unbox',
      visualECommerce: () => '/configuracoes/integracoes/visual-ecommerce',
      magazord: () => '/configuracoes/integracoes/magazord',
      magento: () => '/configuracoes/integracoes/magento',
      yampi: () => '/configuracoes/integracoes/yampi',
    },
    tags: () => '/configuracoes/tags',
    quickReplies: () => '/configuracoes/respostas-rapidas',
    businessHours: () => '/configuracoes/horario-de-atendimento',
    invoices: () => '/configuracoes/faturas',
  },
  customers: {
    index: () => '/clientes',
  },
  messageTemplates: {
    index: () => '/templates-de-mensagem',
    createMessageTemplate: () => '/templates-de-mensagem/novo',
  },
  campaigns: {
    create: () => '/campanhas/novo',
    whatsapp: {
      index: () => '/campanhas/whatsapp',
      details: (campaignId: string) => `/campanhas/whatsapp/${campaignId}`,
    },
    sms: {
      index: () => '/campanhas/sms',
      details: (campaignId: string) => `/campanhas/sms/${campaignId}`,
    },
  },
  reports: {
    index: () => '/reports/',
  },
};
